import React, { Fragment, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import clsx from "clsx";

import { Hidden, Breakpoint } from "../utils";

import { IconSlimCross } from "../icon";

import { ButtonVariant, ButtonSize, Button } from "../click/Button";

export type ModalState = {
	isOpen: boolean;
	closeModal: () => void;
	openModal: () => void;
	setIsOpen: (isOpen: boolean) => void;
};

export function useModalState(openInitial = false) {
	const [isOpen, setIsOpen] = useState(openInitial);

	const closeModal = () => setIsOpen(false);
	const openModal = () => setIsOpen(true);

	return { isOpen, openModal, closeModal, setIsOpen };
}

export enum ModalVariant {
	Default = "default",
	Fullscreen = "fullscreen",
}

export enum ModalHeaderPosition {
	Outside = "outside",
	Inside = "inside",
}

export enum ModalInnerVariant {
	Default = "default",
	Card = "card",
	Fullscreen = "fullscreen",
}

const buttonVariantFor = {
	[ModalVariant.Default]: ButtonVariant.InvertedSecondary,
	[ModalVariant.Fullscreen]: ButtonVariant.Secondary,
};

export type ModalProps = {
	children?: React.ReactNode;
	disclosure?: React.ReactNode;
	modalTitle?: string;
	hasCloseButton?: boolean;
	variant?: ModalVariant;
	innerVariant?: ModalInnerVariant;
	headerPosition?: ModalHeaderPosition;
	openInitial?: boolean;
	hideOnClickOutside?: boolean;
	modalState?: ModalState;
	ariaLabel?: string;
	onClose?: () => void;
	onClickOutside?: Dialog.DialogContentProps["onInteractOutside"];
};

export const Modal = ({
	children,
	variant = ModalVariant.Default,
	headerPosition = ModalHeaderPosition.Outside,
	innerVariant = ModalInnerVariant.Default,
	disclosure,
	openInitial = false,
	hasCloseButton = true,
	hideOnClickOutside = true,
	modalTitle,
	modalState,
	ariaLabel,
	onClose,
	onClickOutside,
	...rest
}: ModalProps) => {
	const hasModalHeader = Boolean(modalTitle || hasCloseButton);
	const defaultModalState = useModalState(openInitial);
	const { isOpen, closeModal, setIsOpen } = modalState || defaultModalState;

	const buttonVariant = buttonVariantFor[variant];

	const headerOutside =
		hasModalHeader && headerPosition === ModalHeaderPosition.Outside;

	const headerInside =
		hasModalHeader && headerPosition === ModalHeaderPosition.Inside;

	return (
		<Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
			{disclosure && <Dialog.Trigger asChild>{disclosure}</Dialog.Trigger>}
			<Dialog.Portal>
				<Dialog.Overlay
					className={clsx(
						"z-modal animate-fade-in fixed inset-0",
						variant === ModalVariant.Default && "bg-modal/75",
						variant === ModalVariant.Fullscreen && "bg-white",
					)}
				>
					{headerOutside && (
						<div
							data-testid="jux-modal-header"
							className={clsx(
								"grid h-[60px] grid-cols-[repeat(3,1fr)] items-center border-b px-6 md:h-[75px] md:px-14",
								variant === ModalVariant.Default && "border-white text-white",
								variant === ModalVariant.Fullscreen &&
									"text-secondary border-muted",
							)}
						>
							<div className="col-span-2 text-left lg:col-start-2 lg:col-end-3 lg:text-center">
								{modalTitle && (
									<Dialog.Title className="text-subheading-default">
										{modalTitle}
									</Dialog.Title>
								)}
							</div>
							{hasCloseButton && (
								<Fragment>
									<Hidden from={Breakpoint.MD}>
										<button
											className="justify-self-end text-lg text-white"
											type="button"
											onClick={closeModal}
											aria-label="Close modal button"
											data-testid="jux-modal-close-button"
										>
											<IconSlimCross />
										</button>
									</Hidden>
									<Hidden to={Breakpoint.MD}>
										<Button
											variant={buttonVariant}
											size={ButtonSize.ExtraSmall}
											iconLeft={<IconSlimCross />}
											className="justify-self-end"
											onClick={closeModal}
											aria-label="Close modal button"
											data-testid="jux-modal-close-button"
										>
											Close
										</Button>
									</Hidden>
								</Fragment>
							)}
						</div>
					)}
				</Dialog.Overlay>
				<Dialog.Content
					className={clsx(
						"z-modal animate-fade-in fixed left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 transform focus:outline-none",
						headerInside && ["min-h-[100vh] pt-0 md:min-h-[0px]"],
						headerOutside && ["top-[calc(50%-75px)] mt-[75px]"],
						innerVariant === ModalInnerVariant.Default && [
							"max-w-[800px] self-center bg-white p-6",
						],
						innerVariant === ModalInnerVariant.Card && [
							"w-full max-w-[560px] self-center overflow-auto rounded-md bg-white p-6 shadow-2xl md:max-h-[90vh]",
						],
						innerVariant === ModalInnerVariant.Fullscreen && [
							"flex w-full flex-col bg-transparent md:self-center",
							headerInside && "overflow-scroll h-full pb-4",
						],
					)}
					onInteractOutside={(e) => {
						e.preventDefault();
						if (hideOnClickOutside) {
							closeModal();
						}
						if (onClickOutside) {
							onClickOutside(e);
						}
					}}
					onCloseAutoFocus={onClose}
					aria-label={ariaLabel}
					{...rest}
				>
					{headerInside && (
						<div
							className="relative z-10 flex items-center justify-between bg-white py-4 px-6 md:px-0 md:py-6"
							data-testid="jux-modal-header"
						>
							<div>
								{modalTitle && (
									<Dialog.Title className="text-secondary text-subheading-default">
										{modalTitle}
									</Dialog.Title>
								)}
							</div>
							<button
								className="text-secondary justify-self-end text-2xl"
								type="button"
								onClick={closeModal}
								aria-label="Close modal button"
								data-testid="jux-modal-close-button"
							>
								<IconSlimCross />
							</button>
						</div>
					)}
					<div className="px-6 md:px-0">{children}</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
